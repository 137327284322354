import { Fields } from "./Fields";

export type TimbratureDipendentiKeys = keyof TimbratureDipendenti;

export const elementIdProps: TimbratureDipendentiKeys[] = ["idTimbratura"];
export const elementRenderProps: TimbratureDipendentiKeys[] = ["tracciato"];

export const allFields: Fields[] = [
    {
        field: "data", type: 'date', titleKey: "dateParam.1", required: false, show: true, sort: 'desc',
    },
    {
        field: "tracciato", type: 'string', titleKey: "tracedParam", required: false, show: true,
    },
    {
        field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: false, show: true,
        // validate: (x: string, helperText: string) => validateInputNumberMinMax(0, 5, 5, false, helperText, false), keyTradValidation2: "",
    },
    {
        field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: true,
        // validate: (x: string, helperText: string) => validateInputNumberMinMax(0, 5, 5, false, helperText, false), keyTradValidation2: "",
    },
    {
        field: "versoTimbratura", type: 'string', titleKey: "clockingDirectionParam", required: false, readonly: false, show: true, lookupField: true,
        // validate: (x: string, helperText: string) => validateInputTextMinLen(x, 1, false, helperText, false), keyTradValidation2: "shortParam3Validation",
    },
    {
        field: "causale", type: 'string', titleKey: "explanationParam", required: false, readonly: false, show: true, lookupField: true,
        // validate: (x: string, helperText: string) => validateInputNumberMinMax(0, 1, 4, false, helperText, false), keyTradValidation2: "shortParam3Validation",
    },
    {
        field: "modelloTerminale", type: 'string', titleKey: "Modello Terminale", required: false, readonly: false, show: true,
    },
    {
        field: "presenza", type: 'boolean', titleKey: "Presenza", required: false, readonly: false, show: true,
    },
    {
        field: "indirizzoIPV4", type: 'string', titleKey: "IPv4Param", required: false, readonly: false, show: true,
        // validate: (x: string, helperText: string) => validateInputNumberMinMax(0, 7, 15, false, helperText, false), keyTradValidation2: "shortParam3Validation",
    },
    {
        field: "identificativoTerminale", type: 'string', titleKey: "terminalIdParam", required: false, readonly: false, show: true,
        // validate: (x: string, helperText: string) => validateInputNumberMinMax(0, 1, 2, false, helperText, false), keyTradValidation2: "shortParam3Validation",
    },
    {
        field: "statoFunzionamentoTerminale", type: 'string', titleKey: "terminalStateParam", required: false, readonly: false, show: true,
        // validate: (x: string, helperText: string) => validateInputTextMinLen(x, 1, false, helperText, false), keyTradValidation2: "shortParam3Validation",
    },
];


export const nuovaPresenzaTimbratura: Fields[] = [
    {
        field: "data", type: 'date', titleKey: "dateParam.1", required: false, show: true,
    },
    {
        field: "causale", titleKey: "causale", required: false, readonly: false, show: true,
    },
    {
        field: "entrata", titleKey: "entrata", required: false, readonly: false, show: true,
    },
    {
        field: "uscita", titleKey: "uscita", required: false, readonly: false, show: true,
    },
];

export interface TimbratureDipendenti {
    idTimbratura?: number,
    tracciato: string,
    oraInizioTurno: string,
    oraFineTurno: string,
    modelloTerminale: string,
    indirizzoIPV4: string,
    identificativoTerminale?: string,
    statoFunzionamentoTerminale?: string,
    matricola?: string,
    versoTimbratura?: string,
    causale?: string,
    data?: string,
    eliminato: boolean,
    version: number,
}










