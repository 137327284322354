import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AbilitazioniUtentiKeys = keyof AbilitazioniUtenti;

export const elementIdProps: AbilitazioniUtentiKeys[] = ["nomeUtente", "idUtenteAbilitazione"];
export const elementRenderProps: AbilitazioniUtentiKeys[] = ["nomeUtente"];

export const allFields: Fields[] = [
  {
    field: "nomeUtente", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, true), keyTradValidation: "size3to255ParamValidation",
    show: true, titleKey: "usernameParam"
  },
  {
    field: "strutturaNome", required: false, show: true, titleKey: "structureParam"

  },
  {
    field: "idStruttura", required: false, lookupField: true, show: false, titleKey: "structureParam"
  },
  {
    field: "dipartimentoNome", required: false, show: true, titleKey: "departmentParam"
  },

  {
    field: "idDipartimento", required: false, lookupField: true, show: false, titleKey: "departmentParam"
  },
  {
    field: "unitaOperativaNome", required: false, show: true, titleKey: "operationalUnitParam"
  },
  {
    field: "idUnitaOperativa", required: false, lookupField: true, show: false, titleKey: "operationalUnitParam"
  },
  {
    field: "idAnagraficaSoggetto", required: false, autocomplete: false, show: true, titleKey: "nominativeParam"
  },
]

export interface AbilitazioniUtenti {
  nomeUtente: string;
  idUtenteAbilitazione: number;
  idStruttura: number;
  strutturaNome: string;
  idDipartimento: number | null;
  dipartimentoNome: string | null;
  idUnitaOperativa: number | null;
  unitaOperativaNome: string | null;
  idAnagraficaSoggetto: number;
  anagraficaSoggettoNome: string;
  anagraficaSoggettoCognome: string;
  inserimentoUtente: string;
  version: number;

  /**
   * private Long idOrganizzazione;

  private String nomeUtente;

  private Long idUtenteAbilitazione;

  private Long idStruttura;

  private String strutturaNome;

  private Long idDipartimento;

  private String dipartimentoNome;

  private Long idUnitaOperativa;

  private String unitaOperativaNome;

  private Long idAnagraficaSoggetto;

  private String anagraficaSoggettoNome;

  private String anagraficaSoggettoCognome;

  private String inserimentoUtente;

  private Long version;
   */
}