import React, { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";

import { Fields } from "../../../../../models/Fields";
import { allFields, Timbrature, TimbratureKeys } from '../../../../../models/Timbrature';

import ReadOnlyMaterialTable from "../../../tables/ReadOnly/ReadOnlyMaterialTable";

import { fetchAll as fetchTurniLookup } from '../../../../../store/slices/turniSlice';
import { fetchAll as fetchQualificheLookup } from '../../../../../store/slices/qualificheSlice';
import { VersoTimbraturaLookup } from "../../../../../utils/utildata";
import { fetchAllValid, } from "../../../../../store/slices/timbratureSlice";
import { Button, Typography } from "@material-ui/core";

import GTModal from "../../../modal/GTModal";
import Maps from "../../../maps/Maps";

interface TimbratureNonAssegnateProps {
  validData: Timbrature[];
}

const TimbratureNonAssegnate = (props: TimbratureNonAssegnateProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const title = t('stampingsNotAssigned');

  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimenti.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperative.lookup);
  const lookupTurniDesc = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualificheDescBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);

  const [map, setMap] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  }

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Timbrature>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn) && !['buttonDettagli', 'button'].includes(f.field)).map((f) => {
        let obj: Column<Timbrature> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as TimbratureKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render)
          obj.render = f.render;

        if (f.defaultGroupOrder)
          obj.defaultGroupOrder = f.defaultGroupOrder;

        // lookups
        if (f.lookupField)
          switch (f.field) {
            case 'idStruttura':
              obj.lookup = lookupStrutture;
              break;
            case 'idDipartimento':
              obj.lookup = lookupDipartimenti;
              break;
            case 'idUnitaOperativa':
              obj.lookup = lookupUnitaOperative;
              break;
            case 'idTurno':
              obj.lookup = lookupTurniDesc;
              break;
            case 'idQualifica':
              obj.lookup = lookupQualificheDescBreve;
              break;
            case 'versoTimbratura':
              obj.lookup = VersoTimbraturaLookup;
              break;
          }

        if (f.field === 'nominativo') {
          obj.emptyValue = undefined;
          obj.render = (rowData: Timbrature) => {
            return rowData.cognome + ' ' + rowData.nome;
          }
        } else if (f.field === 'tracciato') {
          obj.render = (rowData: Timbrature) => {
            return <Button
              onClick={() => {
                handleOpen();
                const token = rowData.tracciato.split('+')?.[1];
                let lat = token?.split('N')?.[0].slice(1);
                let lng = token?.split('N')?.[1];

                lat = lat?.slice(0, lat?.lastIndexOf('.'));
                lng = lng?.slice(0, lng?.lastIndexOf('.'));

                setMap({ lat: Number(lat), lng: Number(lng) });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        return obj;
      })
    )
  }, [allFieldsState, excludedFieldInTable, lookupDipartimenti, lookupQualificheDescBreve, lookupStrutture, lookupTurniDesc, lookupUnitaOperative, t]);

  /**
   * fetch lookups
   */
  useEffect(() => {
    dispatch(fetchTurniLookup());
    dispatch(fetchQualificheLookup());
  }, [dispatch]);

  const validTimbratureNonAssegnate = useAppSelector(state => state.timbrature.validTimbrature);
  const statusValidTimbrature = useAppSelector(state => state.timbrature.statusValidTimbrature);
  const errorBE = useAppSelector(state => state.presenze.error);

  const [data, setData] = useState<Timbrature[]>([]);

  useEffect(() => {
    setData(validTimbratureNonAssegnate.filter(timbratura => {
      return !timbratura.presenza
    }));
  }, [lookupDipartimenti, lookupStrutture, lookupUnitaOperative, validTimbratureNonAssegnate]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const Map = useMemo(() => {
    return map && map.lat && map.lng
      ? <Maps
        map={map}
      />
      : null
  }, [map]);

  return (<>
    <ReadOnlyMaterialTable
      title={title}
      columns={columns}
      data={data}
      fetchAllValid={fetchAllValid}
      statusValid={statusValidTimbrature}
      errorBE={errorBE}
      logoUri={logoUri}
      columnsButton={true}
      extraOptions={{
        paging: true,
        search: true,
        draggable: false,
        maxBodyHeight: 1000,
        minBodyHeight: 500,
      }}
    />
    {
      open &&
      <GTModal
        openState={{
          open,
          setOpen
        }}
      >
        {
          Map ??
          <Typography>
            Impossibile rilevare la posizione
          </Typography>
        }
      </GTModal>
    }
  </>
  )
}
export default TimbratureNonAssegnate;