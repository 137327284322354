import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type DipartimentoKeys = keyof Dipartimento;

export const elementIdProps: DipartimentoKeys[] = ["idStruttura", "idDipartimento"];
export const elementRenderProps: DipartimentoKeys[] = ["nome"];

export const allFields: Fields[] = [
  // { field: "idStruttura", required:true, show:true, titleKey: "structureParam" },
  {
    field: "nome", type: "string", required: true,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 60, false, helperText, true), keyTradValidation: "size3to60ParamValidation",
    show: true, titleKey: "nameParam", colsNum: 6
  },

  {
    field: "idCentroDiCosto", required: true, show: true, titleKey: "costCenterParam", colsNum: 6, lookupField: true,
  },


]
export interface Dipartimento {
  idStruttura: number,
  idDipartimento: number,
  nome: string,
  idCentroDiCosto: number,
  centroDiCostoDescrizione: string,
  version: number
}

export interface LookupDipartimentoElem {
  idStruttura: number,
  idDipartimento: number,
  nome: string
}