import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { CentroDiCosto, CentroDiCostoKeys, elementIdProps } from '../../models/CentriDiCosto';

const microservice = "centridicosto";
const entity = "centridicosto";

interface CentriDiCostoState {
  statusValidCentriDiCosto: StatusEnum,
  validCentriDiCosto: CentroDiCosto[],
  statusDeletedCentriDiCosto: StatusEnum,
  deletedCentriDiCosto: CentroDiCosto[],
  centroDiCosto: CentroDiCosto | null,
  lookup: Lookup,
  error: string | null
}

const initialState: CentriDiCostoState = {
  statusValidCentriDiCosto: StatusEnum.Succeeded,
  validCentriDiCosto: [],
  statusDeletedCentriDiCosto: StatusEnum.Succeeded,
  deletedCentriDiCosto: [],
  centroDiCosto: null,
  lookup: {},
  error: null
}

export const fetchById = createAsyncThunk(microservice + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as CentroDiCosto;
});

export const fetchAllValid = createAsyncThunk(microservice + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as CentroDiCosto[];
});

export const fetchAllDeleted = createAsyncThunk(microservice + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as CentroDiCosto[];
});

export const insert = createAsyncThunk(microservice + '/insert', async (centroDiCosto: CentroDiCosto) => {
  const response = await create(centroDiCosto, microservice, entity);
  return response.data as CentroDiCosto;
});

export const update = createAsyncThunk(microservice + '/update', async (centroDiCosto: CentroDiCosto) => {
  const response = await upd(centroDiCosto, microservice, entity);
  return response.data as CentroDiCosto;
});

export const logicalDel = createAsyncThunk(microservice + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restore = createAsyncThunk(microservice + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const centriDiCostoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: CentriDiCostoState) => {
      return initialState;;
    },
    resetError: (state: CentriDiCostoState) => {
      state.error = initialState.error;
      state.statusValidCentriDiCosto = initialState.statusValidCentriDiCosto;
      state.statusDeletedCentriDiCosto = initialState.statusDeletedCentriDiCosto;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCentriDiCosto = StatusEnum.Failed;
      }
      state.centroDiCosto = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<CentroDiCosto>) => {
      state.statusValidCentriDiCosto = StatusEnum.Succeeded;
      state.centroDiCosto = payload;
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCentriDiCosto = StatusEnum.Failed;
      }
      state.validCentriDiCosto = [];
      state.lookup = {};
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<CentroDiCosto[]>) => {
      state.statusValidCentriDiCosto = StatusEnum.Succeeded;
      state.validCentriDiCosto = payload ?? [];
      let newLookup: Record<number, string> = {}
      payload.forEach(t => newLookup[t.idCentroDiCosto] = t.descrizione + ' (' + t.codice + ')');
      // state.lookup = newLookup;
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCentriDiCosto = StatusEnum.Failed;
      }
      state.deletedCentriDiCosto = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<CentroDiCosto[]>) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Succeeded;
      state.deletedCentriDiCosto = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCentriDiCosto = StatusEnum.Failed;
      }
      state.centroDiCosto = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<CentroDiCosto>) => {
      state.statusValidCentriDiCosto = StatusEnum.Succeeded;
      state.centroDiCosto = payload;
      state.validCentriDiCosto = state.validCentriDiCosto.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCentriDiCosto = StatusEnum.Failed;
      }
      state.centroDiCosto = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<CentroDiCosto>) => {
      state.statusValidCentriDiCosto = StatusEnum.Succeeded;
      state.validCentriDiCosto = state.validCentriDiCosto.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.centroDiCosto = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidCentriDiCosto = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidCentriDiCosto = StatusEnum.Succeeded;
      const deleted = state.validCentriDiCosto.find(el => payload.ids.every(idObj => el[idObj.name as CentroDiCostoKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedCentriDiCosto = state.deletedCentriDiCosto.concat([deleted]);
      }
      state.validCentriDiCosto = state.validCentriDiCosto.filter(el => payload.ids.some(idObj => el[idObj.name as CentroDiCostoKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCentriDiCosto = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Succeeded;
      const valid = state.deletedCentriDiCosto.find(el => payload.ids.every(idObj => el[idObj.name as CentroDiCostoKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validCentriDiCosto = state.validCentriDiCosto.concat([valid]);
      }
      state.deletedCentriDiCosto = state.deletedCentriDiCosto.filter(el => payload.ids.some(idObj => el[idObj.name as CentroDiCostoKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedCentriDiCosto = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedCentriDiCosto = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedCentriDiCosto = StatusEnum.Succeeded;
      state.deletedCentriDiCosto = state.deletedCentriDiCosto.filter(el => payload.ids.some(idObj => el[idObj.name as CentroDiCostoKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = centriDiCostoSlice.actions;
export default centriDiCostoSlice.reducer;