import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, UnitaOperativaTurnoNumeroAddetti, allFields, UnitaOperativaTurnoNumeroAddettiKeys } from "../../../models/UnitaOperativeTurniNumeroAddetti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllValidByFilter, insert, physicalDel, update, reset as resetNumeroAdetti, resetError as resetErrorNumeroAddetti } from "../../../store/slices/unitaOperativeTurniNumeroAddettiSlice";
import { fetchAllValid as fetchTurni, reset as resetTurni, resetError as resetErrorTurni } from "../../../store/slices/turniSlice";
import { fetchAllValid as fetchQualifiche, reset as resetQualifiche, resetError as resetErrorQualifiche } from "../../../store/slices/qualificheSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { unitaOperativePath } from "../../../utils/utilconst";
import { TextField } from "@material-ui/core";
import { WeekList } from "../../../utils/utildata";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";

interface UnitaOperativeTurniNumeroAddettiWProps {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number
}

const UnitaOperativeTurniNumeroAddettiW = ({ idStruttura, idDipartimento, idUnitaOperativa }: UnitaOperativeTurniNumeroAddettiWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, unitaOperativePath));
  const errorBE = useAppSelector(state => state.unitaOperativeTurniNumeroAddetti.error);

  const fixedProps = useMemo(() => { return { "idStruttura": idStruttura, "idDipartimento": idDipartimento, "idUnitaOperativa": idUnitaOperativa } }, [idStruttura, idDipartimento, idUnitaOperativa]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchTurni());
    dispatch(fetchQualifiche());
  }, [dispatch]);


  const lookupTurni = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualifiche = useAppSelector(state => state.qualifiche.lookupDescrizione);
  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupUO = useAppSelector(state => state.unitaOperativeFiltered.lookup);

  const title = t('shiftservicehoursTitle');

  const resetError = useCallback(() => {
    dispatch(resetErrorNumeroAddetti());
    dispatch(resetErrorTurni());
    dispatch(resetErrorQualifiche());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<UnitaOperativaTurnoNumeroAddetti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<UnitaOperativaTurnoNumeroAddetti> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as UnitaOperativaTurnoNumeroAddettiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'idTurno':
              obj.lookup = lookupTurni;
              break;
            case 'idQualifica':
              obj.lookup = lookupQualifiche;
              break;
          }
        }

        /* +++ */
        if (f.field === "giornoSettimana") {
          let daysLookup: Record<number, string> = {};
          WeekList.forEach((day, index) => daysLookup[index] = t(day));
          obj.lookup = daysLookup;
        } else if (f.field === "dotazioneOttimale") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }
                }
                inputProps={{ "min": 1, "max": 24 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        } else if (f.field === "dotazioneMinima") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }
                }
                inputProps={{ "min": 1, "max": 24 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        } else if (f.field === "numeroReperibilita") {
          obj.editComponent = props => {
            return (
              <TextField
                type="number"
                value={props.value ? props.value : ""}
                onChange={(e) => {
                  (e.target.value === "" || (Number(e.target.value) >= 1 && Number(e.target.value) <= 24)) && props.onChange(e.target.value)
                }
                }
                inputProps={{ "min": 1, "max": 24 }}
                error={props.error}
                //@ts-ignore
                helperText={props.helperText}
              />
            )
          }
        }
        /* +++ */
        return obj;
      }));
  }, [lookupTurni, lookupQualifiche, t, allFieldsState, excludedFieldInTable]);

  const validUnitaOperativaTurniNumeroAddetti = useAppSelector(state => state.unitaOperativeTurniNumeroAddetti.validUnitaOperativaTurniNumeroAddetti);

  const statusValidUnitaOperativaTurniNumeroAddetti = useAppSelector(state => state.unitaOperativeTurniNumeroAddetti.statusValidUnitaOperativaTurniNumeroAddetti);

  const [data, setData] = useState<Array<UnitaOperativaTurnoNumeroAddetti>>([]);

  useEffect(() => {
    setData(validUnitaOperativaTurniNumeroAddetti);
  }, [validUnitaOperativaTurniNumeroAddetti]);


  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        if (exportDataExtra.head)
          exportDataExtra.head['Struttura'] = lookupStr[fixedProps[elem]];
        break;
      case 'idDipartimento':
        if (exportDataExtra.head)
          exportDataExtra.head['Dipartimento'] = lookupDip[fixedProps[elem]];
        break;
      case 'idUnitaOperativa':
        if (exportDataExtra.head)
          exportDataExtra.head['Unità Operativa'] = lookupUO[fixedProps[elem]];
        break;
    }
  });

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetNumeroAdetti());
      dispatch(resetTurni());
      dispatch(resetQualifiche());
    };
  }, [dispatch]);

  return <CrudMaterialTableWithoutLogicDelete
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchAllValidByFilter}
    insert={insert}
    physicalDel={physicalDel}
    update={update}
    statusValid={statusValidUnitaOperativaTurniNumeroAddetti}
    errorBE={errorBE}
    fixedProps={fixedProps}
    exportDataExtra={exportDataExtra}
    logoUri={logoUri}
    resetErrorCallback={resetError}
    extraOptions={{
      maxBodyHeight: 460,
    }}
  />
}
export default UnitaOperativeTurniNumeroAddettiW;