
import { Fields } from "./Fields";

export type DipartimentiSediKeys = keyof DipartimentiSedi;

export const elementIdProps: DipartimentiSediKeys[] = ['idStruttura', 'idDipartimento', 'idDipartimentoSede'];
export const elementRenderProps: DipartimentiSediKeys[] = ['descrizione'];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', required: true, show: true, titleKey: "descriptionParam"
  },
  {
    field: "indirizzo", type: 'string', required: false, show: true, titleKey: "addressParam"
  },
  {
    field: "latitudine", type: 'string', required: true, show: true, titleKey: "latitudeParam"
  },
  {
    field: "longitudine", type: 'string', required: true, show: true, titleKey: "longitudeParam"
  },
  {
    field: "mappa", required: false, show: true, titleKey: "positionParam", editable: "never"
  }
];

export interface DipartimentiSedi {
  idStruttura: number,
  idDipartimento: number,
  idDipartimentoSede: number,

  descrizione: string,
  indirizzo?: string,
  latitudine: string,
  longitudine: string,

  version: number,
};
