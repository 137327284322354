import { validateInputNumberMin, validateInputNumberMinMax, validateInputTextFixedLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type UnitaOperativaTurnoNumeroAddettiKeys = keyof UnitaOperativaTurnoNumeroAddetti;

export const elementIdProps: UnitaOperativaTurnoNumeroAddettiKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa", "idQualifica", "idTurno", "giornoSettimana"];
export const elementRenderProps: UnitaOperativaTurnoNumeroAddettiKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idQualifica", required: true, show: true, titleKey: "qualificationParam", editable: "onAdd", lookupField: true,
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },

  {
    field: "idTurno", required: true, show: true, titleKey: "shiftParam", editable: "onAdd", lookupField: true,
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },

  {
    field: "giornoSettimana", required: true, show: true, titleKey: "weekDayParam", editable: "onAdd",
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },

  {
    field: "numeroLegale", type: "numeric", required: false, show: true, titleKey: "EquipmentTabLabelMinimum",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, true), keyTradValidation: "greaterThanZeroValidation",
  },

  {
    field: "numeroAddetti", type: "numeric", required: false, show: true, titleKey: "EquipmentTabLabeoptimal",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, true), keyTradValidation: "greaterThanZeroValidation",
  },

  {
    field: "numeroReperibilitaRichieste", type: "numeric", required: false, show: true, titleKey: "availabilityParamRequest",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, true), keyTradValidation: "greaterThanZeroValidation",
  },
  {
    field: "oreTurno", type: "numeric", required: false, show: true, titleKey: "workShiftParam",
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 24, false, helperText, true), keyTradValidation: "between0And24Validation",
  },
  {
    field: "minutiTurno", type: "numeric", required: false, show: true, titleKey: "minutesShiftParam",
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation",
  },
  {
    field: "oraInizioTurno", type: "string", required: false, show: true, titleKey: "turnStartParam",
    validate: (x: string, helperText: string) => validateInputTextFixedLen(x, 5, helperText, true), keyTradValidation: "size5to5ParamValidation",
  },
  {
    field: "oraFineTurno", type: "string", required: false, show: true, titleKey: "turnEndDateParam",
    validate: (x: string, helperText: string) => validateInputTextFixedLen(x, 5, helperText, true), keyTradValidation: "size5to5ParamValidation",
  },
]
export interface UnitaOperativaTurnoNumeroAddetti {

  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  idQualifica: number,
  qualificaDescrizioneBreve: string,
  idTurno: number,
  turnoDescrizione: string,
  giornoSettimana: number,
  oreTurno: number,
  minutiTurno: number,
  oraInizioTurno: string,
  oraFineTurno: string,
  numeroAddetti: number,  // ottimale
  numeroLegale: number,   // minimo
  numeroReperibilitaRichieste: number,
  version: number

}


