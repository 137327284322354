import { validateDate, validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiUnitaOperativaKeys = keyof AnagraficaSoggettiUnitaOperativa;

export const elementIdProps: AnagraficaSoggettiUnitaOperativaKeys[] = ["idAnagraficaSoggetto", "dataAssegnazione"];
export const elementRenderProps: AnagraficaSoggettiUnitaOperativaKeys[] = [];

export const allFields: Fields[] = [
    {
        field: "unitaOperativaNome", required: false, show: true, titleKey: "operationalUnitParam", editable: 'never'
    },
    {
        field: "dataAssegnazione", type: "date", required: true, show: true, titleKey: "assignDateParam", sort: 'desc', readonly: false, group: 'noGroup',
        editable: 'onAdd',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },

    {
        field: "idStruttura", lookupField: true, required: true, show: false, titleKey: "structureParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    {
        field: "idDipartimento", lookupField: true, required: true, show: false, titleKey: "departmentParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "idUnitaOperativa", lookupField: true, required: true, show: false, titleKey: "operationalUnitParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "unitaOperativaNome", required: true, show: false, titleKey: "operationalUnitParam", editable: "never",
    },

    {
        field: "idQualificaIncarico", lookupField: true, required: true, show: false, titleKey: "jobTitleParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    {
        field: "qualificaIncaricoDescrizioneBreve", required: true, show: true, titleKey: "jobTitleParam", editable: 'never',
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    {
        field: "motivoVariazione", type: "string", required: false, show: true, titleKey: "variationReasonParam",
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation"
    },
]

export const allFieldsUnitaOperativa: Fields[] = [
    {
        field: "unitaOperativaNome", required: false, show: true, titleKey: "operationalUnitParam", editable: 'never'
    },
    {
        field: "dataAssegnazione", type: "date", required: true, show: true, titleKey: "assignDateParam", sort: 'desc', readonly: false, group: 'noGroup',
        editable: 'onAdd',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },
    {
        field: "idStrutturaUnitaOperativa", lookupField: true, required: false, show: false, titleKey: "structureParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "idDipartimentoUnitaOperativa", lookupField: true, required: false, show: false, titleKey: "departmentParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "idUnitaOperativa", lookupField: true, required: true, show: false, titleKey: "operationalUnitParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "unitaOperativaNome", required: true, show: false, titleKey: "operationalUnitParam", editable: "never",
    },
    {
        field: "idQualificaIncarico", lookupField: true, required: true, show: false, titleKey: "jobTitleParam",
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "qualificaIncaricoDescrizioneBreve", required: true, show: false, titleKey: "jobTitleParam", editable: 'never',
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },
    {
        field: "motivoVariazioneUnitaOperativa", type: "string", required: false, show: false, titleKey: "variationReasonParam",
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 100, false, helperText, false), keyTradValidation: "size3to100ParamValidation"
    },
]

export interface AnagraficaSoggettiUnitaOperativa {
    cognome: string,
    nome: string,
    idAnagraficaSoggetto: number,
    dataAssegnazione: string,
    idStruttura: number,
    idDipartimento: number,
    idUnitaOperativa: number,
    unitaOperativaNome: string,
    motivoVariazione?: string,
    idQualificaIncarico: number,
    qualificaIncaricoDescrizione: string,
    qualificaIncaricoDescrizioneBreve: string,
    descrizioneQualifica: string,
    dataAssegnazionePrecedente?: string,
    unitaOperativaPrecedente: string,
    version: number,
}