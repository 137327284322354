import React, { ReactNode, useEffect, useMemo, useState, /* useEffect */ } from "react";
import { Paper, makeStyles, Theme, createStyles, Badge, withStyles, Typography, Grid, Box, Modal, IconButton, } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import clsx from 'clsx';
import SituazioneUnitaOperativeW from "./tabelle/SituazioneUnitaOperativeW";
import PresenzeDaApprovareW from "./tabelle/PresenzeDaApprovareW";
import ScadenzaPeriodoProvaW from "./tabelle/ScadenzaPeriodoProvaW";
import ScadenzaContrattiW from "./tabelle/ScadenzaContrattiW";
import { ConteggioQualifica } from "../../../../models/ConteggioQualifica";
import { Presenza } from "../../../../models/Presenze";
import { ScadenzaFinePeriodoProva } from "../../../../models/ScadenzaPeriodoProva";
import { ScadenzaContratti } from "../../../../models/ScadenzaContratti";
import { fetchConteggioPerQualificaCompleto } from "../../../../store/slices/presenzeSlice";
import { fetchByIds as fetchAApprovazionePresenze } from "../../../../store/slices/approvazionePresenzeSlice";
import { fetchAll as fetchAllScadenzePeriodoProva } from "../../../../store/slices/scadenzaPeriodoProvaSlice";
import { fetchAll as fetchAllScadenzeContratti } from "../../../../store/slices/scadenzaContrattiSlice";
import { fetchAllValid, } from "../../../../store/slices/timbratureSlice";
import { fetchAll as fetchAllScadenzeVisite } from "../../../../store/slices/codiciVisiteSlice";
import { Timbrature } from "../../../../models/Timbrature";
import TimbratureNonAssegnateW from "./tabelle/TimbratureNonAssegnateW";
import ScadenzeVisiteW from "./tabelle/ScadenzeVisiteW";
import { ScadenzeVisite } from "../../../../models/ScadenzeVisite";
import { getDateYYYYMMDD_BackEnd } from "../../../../utils/utilfunctions";
import { AssenzaProgrammata } from "../../../../models/AssenzeProgrammate";
import AssenzeDaApprovareW from "./tabelle/AssenzeDaApprovareW";
import { fetchAll as fetchAllFormazione } from "../../../../store/slices/formazioneSlice";
import { ScadenzaFormazione } from "../../../../models/ScadenzaFormazione";
import ScadenzeFormazioneW from "./tabelle/ScadenzeFormazioneW";
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PresenzeMancantiW from "./tabelle/PresenzeMancantiW";
import { PresenzeMancanti } from "../../../../models/PresenzeMancanti";
import { fetchAll } from "../../../../store/slices/presenzeMancantiSlice";
import { fetchByIds } from "../../../../store/slices/assenzeProgrammateSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 'calc(12px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));',
      textAlign: "left",
      color: '#0e59a8',
      margin: 10
    },
    subTitle: {
      fontSize: 'calc(18px + (18 - 12) * ((100vw - 300px) / (1600 - 300)));',
      fontWeight: "bold",
      textAlign: "center",
      color: 'red'
    },
    moreInfoButton: {
      backgroundColor: 'white',
      padding: 2,
      borderRadius: 20,
      width: 450,
      cursor: 'pointer',
      //    boxShadow: '10px 10px 5px black',
      border: 3,
      borderColor: "#0e59a8",
      borderStyle: "solid ",
    },
    container: {
      marginTop: 60,
      maxWidth: 1500,
      maxHeigth: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    icon: {
      fontSize: 70,
      fontStyle: "bold",
    },
  })
);

enum TabIndex {
  SITUAZIONE_UNITA_OPERATIVA,
  // CHIUSURA_GIORNATA,
  // PIANIFICAZIONI_MANCANTI,
  TIMBRATURE_NON_ASSEGNATE,
  PRESENZE_DA_APPROVARE,
  ASSENZE_DA_APPROVARE,
  // SCADENZA_VISITE,
  SCADENZA_PERIODO_DI_PROVA,
  SCADENZA_CONTRATTI,
  SCADENZE_VISITE,
  SCADENZE_FORMAZIONE,
  PRESENZE_MANCANTI
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: any;
//   value: any;
// }

// const TabPanel = (props: TabPanelProps) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       hidden={value !== index}
//       id={'tabpanel-' + index}
//       aria-labelledby={'tab-' + index}
//       {...other}
//     >
//       {value === index && children}
//     </div>
//   );
// }

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      height: 30,
      fontSize: 20,
      padding: 10,
      textAlign: "right",
      position: "relative",
      right: -420,
      top: 30
    },
  }),
)(Badge);

const MonitoraggioW = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const MAX_NOTIFICA = 99;

  // const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.SITUAZIONE_UNITA_OPERATIVA);
  // const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
  //   setTabValue(newValue);
  // };


  // const handleTabChange = (index: TabIndex) => {
  //   return {
  //     id: 'tab-' + index,
  //     'aria-controls': 'tabpanel-' + index,
  //   };
  // }

  const [component, setComponent] = useState<ReactNode | null>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => { setIsOpen(false); }

  const handleOpen = (title: string,) => {
    setComponent(() => {
      switch (title) {
        case titles[0]:
          return <SituazioneUnitaOperativeW validData={filteredValidConteggioQualifica} />

        case titles[1]:

          return <TimbratureNonAssegnateW validData={filteredValidTimbratureNonAssegnate} />

        case titles[2]:

          return <PresenzeDaApprovareW validData={filteredValidPresenzeDaApprovare} />

        case titles[3]:
          return <AssenzeDaApprovareW validData={filteredValidAssenzeDaApprovare} />

        case titles[4]:
          return <ScadenzaPeriodoProvaW validData={filteredValidAnagraficaSoggettiFinePeriodoProva} />

        case titles[5]:
          return <ScadenzaContrattiW validData={filteredValidFineContratto} />

        case titles[6]:
          return <ScadenzeVisiteW validData={filteredValidScadenzeVisite} />

        case titles[7]:
          return <ScadenzeFormazioneW validData={filteredValidScadenzeFormazione} />

        case titles[8]:
          return <PresenzeMancantiW validData={filteredPresenzeMancanti} />
      }
    })
    setIsOpen(true);
  }

  const fixedProps = useMemo(() => {
    return {
      [TabIndex.PRESENZE_DA_APPROVARE]: {
        soloDaApprovare: true,
        inizioData: null,
        fineData: null,
      },
      [TabIndex.ASSENZE_DA_APPROVARE]: {
        soloDaApprovare: true,
        inizioData: null,
        fineData: null,
      },
      [TabIndex.SCADENZA_PERIODO_DI_PROVA]: {
        dataInizio: getDateYYYYMMDD_BackEnd(addDays(new Date(), -15)),
        dataFine: getDateYYYYMMDD_BackEnd(addDays(new Date(), 30)),
      },
      [TabIndex.SCADENZA_CONTRATTI]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(addDays(new Date(), -15)),
      },
      [TabIndex.SCADENZE_VISITE]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      },
      [TabIndex.SCADENZE_FORMAZIONE]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      },
      [TabIndex.PRESENZE_MANCANTI]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      }
    }
  }, []);

  /**
   * Data fetch
   */
  useEffect(() => {
    dispatch(fetchConteggioPerQualificaCompleto());                                             // TabIndex.SITUAZIONE_UNITA_OPERATIVA,
    dispatch(fetchAllValid());                                                             // TabIndex.TIMBRATURE_NON_ASSEGNATE,
    dispatch(fetchAApprovazionePresenze(fixedProps[TabIndex.PRESENZE_DA_APPROVARE]));
    dispatch(fetchByIds(fixedProps[TabIndex.ASSENZE_DA_APPROVARE]));
    dispatch(fetchAllScadenzePeriodoProva(fixedProps[TabIndex.SCADENZA_PERIODO_DI_PROVA]));     // TabIndex.SCADENZA_PERIODO_DI_PROVA
    dispatch(fetchAllScadenzeContratti(fixedProps[TabIndex.SCADENZA_CONTRATTI]))                // TabIndex.SCADENZA_CONTRATTI
    dispatch(fetchAllScadenzeVisite());                                                        // TabIndex.SCADENZE_VISITE
    dispatch(fetchAllFormazione());
    dispatch(fetchAll())

  }, [dispatch, fixedProps]);

  const validConteggioQualifica = useAppSelector((state) => state.presenze.conteggioQualifica);
  const validPresenzeDaApprovare = useAppSelector((state) => state.approvazionePresenze.validPresenze);
  const validAssenzeDaApprovare = useAppSelector((state) => state.assenzeProgrammate.validAssenzeProgrammate);
  const validAnagraficaSoggettiFinePeriodoProva = useAppSelector((state) => state.scadenzaPeriodoProva.validScadenzaPeriodoProva);
  const validAnagraficaSoggettiDataFineContratto = useAppSelector((state) => state.scadenzaContratti.validScadenzaContratti);
  const validTimbrature = useAppSelector((state) => state.timbrature.validTimbrature);
  const validScadenzeVisite = useAppSelector((state) => state.scadenzeVisite.validScadenzeVisite);
  const validFormazione = useAppSelector((state) => state.scadenzaFormazione.validScadenzeFormazione);
  const validPresenzeMancanti = useAppSelector((state) => state.presenzeMancanti.validPresenzeMancanti)



  const [filteredValidConteggioQualifica, setFilteredValidConteggioQualifica] = useState<ConteggioQualifica[]>([]);
  const [filteredValidPresenzeDaApprovare, setFilteredValidPresenzeDaApprovare] = useState<Presenza[]>([]);
  const [filteredValidAssenzeDaApprovare, setFilteredValidAssenzeDaApprovare] = useState<AssenzaProgrammata[]>([]);
  const [filteredValidAnagraficaSoggettiFinePeriodoProva, setFilteredValidAnagraficaSoggettiFinePeriodoProva] = useState<ScadenzaFinePeriodoProva[]>([]);
  const [filteredValidFineContratto, setFilteredValidFineContratto] = useState<ScadenzaContratti[]>([]);
  const [filteredValidTimbratureNonAssegnate, setFilteredValidTimbratureNonAssegnate] = useState<Timbrature[]>([]);
  const [filteredValidScadenzeVisite, setFilteredScadenzeVisite] = useState<ScadenzeVisite[]>([]);
  const [filteredValidScadenzeFormazione, setFilteredFormazione] = useState<ScadenzaFormazione[]>([]);
  const [filteredPresenzeMancanti, setFilteredPresenzeMancanti] = useState<PresenzeMancanti[]>([]);




  useEffect(() => {
    setFilteredValidConteggioQualifica(validConteggioQualifica?.filter(elem => ['Rosso'].includes(elem.colorMonitoring)));
    setFilteredValidPresenzeDaApprovare(validPresenzeDaApprovare);
    setFilteredValidAssenzeDaApprovare(validAssenzeDaApprovare);
    setFilteredValidAnagraficaSoggettiFinePeriodoProva(validAnagraficaSoggettiFinePeriodoProva);
    setFilteredValidFineContratto(validAnagraficaSoggettiDataFineContratto);
    setFilteredValidTimbratureNonAssegnate(validTimbrature.filter(elem => !elem.presenza))
    setFilteredScadenzeVisite(validScadenzeVisite);
    setFilteredFormazione(validFormazione);
    setFilteredPresenzeMancanti(validPresenzeMancanti);

  }, [validAnagraficaSoggettiDataFineContratto, validAnagraficaSoggettiFinePeriodoProva, validPresenzeDaApprovare, validConteggioQualifica, validScadenzeVisite, validTimbrature, validAssenzeDaApprovare, validFormazione, validPresenzeMancanti]);

  const titles = useMemo(() => {
    return [
      t("Unita Operative"),
      t("Timbrature"),
      t("Presenze"),
      t("Assenze"),
      t("Periodo di Prova"),
      t("Contratti"),
      t("Scheda Sanitaria"),
      t("Formazione"),
      t("Presenze ")
    ]


  }, [t])


  const subtitles = useMemo(() => {
    return [
      "Situazione",
      "Assegnazione",
      "Approvazione",
      "Approvazione",
      "Scadenze ",
      "Scadenze",
      "Scadenze",
      "Scadenze",
      "Mancanti "

    ]



    // [titles[1]]: filteredValidTimbratureNonAssegnate,
    // [titles[2]]: filteredValidPresenzeDaApprovare,
    // [titles[3]]: filteredValidAssenzeDaApprovare,






  }, [])


  const dati = useMemo(() => {
    return [

      filteredValidConteggioQualifica,
      filteredValidTimbratureNonAssegnate,
      filteredValidPresenzeDaApprovare,
      filteredValidAssenzeDaApprovare,
      filteredValidAnagraficaSoggettiFinePeriodoProva,
      filteredValidFineContratto,
      filteredValidScadenzeVisite,
      filteredValidScadenzeFormazione,
      filteredPresenzeMancanti
    ]


  }, [filteredPresenzeMancanti, filteredValidAnagraficaSoggettiFinePeriodoProva, filteredValidAssenzeDaApprovare, filteredValidConteggioQualifica, filteredValidFineContratto, filteredValidPresenzeDaApprovare, filteredValidScadenzeFormazione, filteredValidScadenzeVisite, filteredValidTimbratureNonAssegnate]);



  /**
   * Cambiare tab ogni 10 secondi
   */
  // useEffect(() => {
  //   const intervalInSeconds = 10 * 1000;
  //   TODO: controllare se bisogni ricreare un setInterval
  //   setInterval(() => {
  //     setTabValue(state => state + 1 < Object.values(TabIndex).filter(elem => typeof elem === 'string').length ? state + 1 : TabIndex.SITUAZIONE_UNITA_OPERATIVA);
  //   }, intervalInSeconds)
  // }, []);

  // return (
  //   <Paper>
  //     <AppBar position="static" className={classes.appbar}>
  //       <Tabs value={tabValue} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
  //         {/* Situazione Unita Operative */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidConteggioQualifica.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("operatingUnitSituationTitle")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.SITUAZIONE_UNITA_OPERATIVA)}
  //         />

  //         {/* Timbrature Non Assegnate */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidTimbratureNonAssegnate.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("stampingsNotAssigned")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.TIMBRATURE_NON_ASSEGNATE)}
  //         />

  //         {/* Presenze Da Approvare */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidPresenzeDaApprovare?.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("pendingAttendanceTitle")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.PRESENZE_DA_APPROVARE)}
  //         />

  //         {/*Assenze Da Approvare */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidAssenzeDaApprovare?.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("assenze da approvare")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.ASSENZE_DA_APPROVARE)}
  //         />


  //         {/* Scadenza Periodo Di Prova */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidAnagraficaSoggettiFinePeriodoProva.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("expirationTrialTitle")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.SCADENZA_PERIODO_DI_PROVA)}
  //         />

  //         {/* Scadenza Contratti */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidFineContratto.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("expirationContractTitle")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.SCADENZA_CONTRATTI)}
  //         />

  //         {/* Scadenza Visite */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidScadenzeVisite.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("deadlineSchedaSanitaria")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.SCADENZE_VISITE)}
  //         />



  //         {/* Scadenza Formazione */}
  //         <Tab
  //           className={classes.tab}
  //           label={
  //             <StyledBadge
  //               badgeContent={filteredValidScadenzeFormazione.length}
  //               color="error"
  //               max={MAX_NOTIFICA}
  //             >
  //               <span>
  //                 {t("deadlineforFormazione")}
  //               </span>
  //             </StyledBadge>
  //           }
  //           {...handleTabChange(TabIndex.SCADENZE_FORMAZIONE)}
  //         />

  //       </Tabs>
  //     </AppBar>

  //     {/*************************************************** TABS ******************************************************/}

  //     {/* Situazione Unita Operative */}
  //     <TabPanel value={tabValue} index={TabIndex.SITUAZIONE_UNITA_OPERATIVA}>
  //       <SituazioneUnitaOperativeW validData={filteredValidConteggioQualifica} />
  //     </TabPanel>



  //     {/* Timbrature Non Assegnate */}
  //     <TabPanel value={tabValue} index={TabIndex.TIMBRATURE_NON_ASSEGNATE}>
  //       <TimbratureNonAssegnateW validData={filteredValidTimbratureNonAssegnate} />
  //     </TabPanel>

  //     {/* Presenze Da Approvare */}
  //     <TabPanel value={tabValue} index={TabIndex.PRESENZE_DA_APPROVARE}>
  //       <PresenzeDaApprovareW validData={filteredValidPresenzeDaApprovare} />
  //     </TabPanel>

  //     {/*Assenze Da Approvare */}
  //     <TabPanel value={tabValue} index={TabIndex.ASSENZE_DA_APPROVARE}>
  //       <AssenzeDaApprovareW validData={filteredValidAssenzeDaApprovare} />
  //     </TabPanel>

  //     {/* Scadenza Periodo Di Prova */}
  //     <TabPanel value={tabValue} index={TabIndex.SCADENZA_PERIODO_DI_PROVA}>
  //       <ScadenzaPeriodoProvaW validData={filteredValidAnagraficaSoggettiFinePeriodoProva} />
  //     </TabPanel>

  //     {/* Scadenza Contratti */}
  //     <TabPanel value={tabValue} index={TabIndex.SCADENZA_CONTRATTI}>
  //       <ScadenzaContrattiW validData={filteredValidFineContratto} />
  //     </TabPanel>

  //     {/* Scadenza Visite */}
  //     <TabPanel value={tabValue} index={TabIndex.SCADENZE_VISITE}>
  //       <ScadenzeVisiteW validData={filteredValidScadenzeVisite} />
  //     </TabPanel>

  //       {/* Scadenza Formazione */}
  //       <TabPanel value={tabValue} index={TabIndex.SCADENZE_FORMAZIONE}>
  //       <ScadenzeFormazioneW validData={filteredValidScadenzeFormazione} />
  //     </TabPanel>


  //   </Paper >
  // )


  return <>
    {
      <Grid container justify-content="center">
        {
          titles.map((title, index) => {
            return <Grid key={"title-" + index} item xs={12} sm={12} md={12} lg={4}>
              <Box
                padding={5}
                onClick={() => { handleOpen(title) }}
              >
                <StyledBadge
                  badgeContent={dati[index]?.length}
                  color="error"
                  max={MAX_NOTIFICA}
                />
                <Box className={classes.moreInfoButton}
                  position={"relative"}>

                  <Typography className={classes.title} >
                    {title}
                  </Typography>
                  <Typography className={classes.subTitle}>
                    {subtitles[index]}
                  </Typography>
                  <Box position={"absolute"} right={0} bottom={10}>
                    <ChevronRightIcon className={classes.icon}></ChevronRightIcon>
                  </Box>
                </Box>
              </Box>
            </Grid>
          })
        }
      </Grid>
    }

    <Modal
      className={clsx(classes.container)}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper >
        <Grid container>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Box display='flex' justifyContent='right'>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>

          </Grid  >
          <Grid item xs >
            {component}
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  </>

}


export default MonitoraggioW;


