import { validateDate, } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiTurnoAbilitatoKeys = keyof AnagraficaSoggettiTurnoAbilitato;

export const elementIdProps: AnagraficaSoggettiTurnoAbilitatoKeys[] = ["idAnagraficaSoggetto", "dataTurniAbilitati"];
export const elementRenderProps: AnagraficaSoggettiTurnoAbilitatoKeys[] = [];

export const allFields: Fields[] = [

    {
        field: "dataTurniAbilitati", type: "date", required: true, show: true, titleKey: "dateOfEnabledShiftsParam", sort: 'desc', readonly: false, group: 'noGroup', editable: 'onAdd',
        validate: (x: string, helperText: string) => validateDate(x, 1990, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },

    {
        field: "combinazioneTurni", required: true, show: true, titleKey: "shiftCombinationParam", lookupField: true,
    },
    {
        field: "motivoVariazione", type: "string", required: false, show: true, titleKey: "variationReasonParam",
    },
    {
        field: "idAnagraficaSoggettiSchedaSanitaria", required: false, show: true, titleKey: "personalDataOfHealthCardSubjectsParam", lookupField: true,
    },
    {
        field: "riposoSabato", type: "boolean", required: false, show: false, titleKey: "offSaturdayParam", readonly: false, colsNum: 2,
    },
    {
        field: "riposoDomenicaFestivi", type: "boolean", required: false, show: false, titleKey: "offHolidayParam", readonly: false, colsNum: 2,
    },
    {
        field: "richiestaPianificazione", type: "boolean", required: false, show: false, titleKey: "planningRequest", readonly: false, colsNum: 2,
    },
    {
        field: "dataInizioTurnoFisso", type: 'date', required: false, show: true, titleKey: "fixedTurnStartParam",
    },

    {
        field: "turnoFisso", required: false, show: true, titleKey: "fixedTurnParam", lookupField: true,
    },

    {
        field: "minutiElasticita", type: "numeric", required: false, show: false, titleKey: "minuteBoundParam", defaultValue: '0', colsNum: 6, minVal: 0, maxVal: 60
    },

    {
        field: "recuperoRichiesto", type: "boolean", required: false, show: false, titleKey: "recoveryRequiredParam", colsNum: 2
    },

    {
        field: "timbraturaSoloPresenza", type: "boolean", required: false, show: false, titleKey: "stampForPresenceParam", colsNum: 4
    },

]

export const allFieldsTurniAbilitati: Fields[] = [

    {
        field: "dataTurniAbilitati", type: "date", required: true, show: true, titleKey: "dateOfEnabledShiftsParam", sort: 'desc', readonly: false, group: 'noGroup', editable: 'onAdd',
        validate: (x: string, helperText: string) => validateDate(x, 1990, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },
    {
        field: "combinazioneTurni", required: true, show: true, titleKey: "shiftCombinationParam", lookupField: true,
    },
    {
        field: "motivoVariazioneTurniAbilitati", type: "string", required: false, show: false, titleKey: "variationReasonParam"
    },
    {
        field: "idAnagraficaSoggettiSchedaSanitaria", required: false, show: true, titleKey: "personalDataOfHealthCardSubjectsParam", lookupField: true,
    },
    {
        field: "riposoSabato", type: "boolean", required: false, show: false, titleKey: "offSaturdayParam", readonly: false, colsNum: 2,
    },
    {
        field: "riposoDomenicaFestivi", type: "boolean", required: false, show: false, titleKey: "offHolidayParam", readonly: false, colsNum: 2,
    },
    {
        field: "richiestaPianificazione", type: "boolean", required: false, show: false, titleKey: "planningRequest", readonly: false, colsNum: 2,
    },
    {
        field: "dataInizioTurnoFisso", type: 'date', required: false, show: true, titleKey: "fixedTurnStartParam",
    },

    {
        field: "turnoFisso", required: false, show: true, titleKey: "fixedTurnParam", lookupField: true,
    },

    {
        field: "minutiElasticita", type: "numeric", required: false, show: false, titleKey: "minuteBoundParam", defaultValue: '0', colsNum: 6, minVal: 0, maxVal: 60
    },

    {
        field: "recuperoRichiesto", type: "boolean", required: false, show: false, titleKey: "recoveryRequiredParam", colsNum: 2
    },

    {
        field: "timbraturaSoloPresenza", type: "boolean", required: false, show: false, titleKey: "stampForPresenceParam", colsNum: 4
    },
]

export interface AnagraficaSoggettiTurnoAbilitato {
    idAnagraficaSoggetto: number,
    dataTurniAbilitati: string,
    combinazioneTurni: string,
    motivoVariazione: string,
    motivoVariazioneTurniAbilitati: string,
    idAnagraficaSoggettiSchedaSanitaria?: number,
    anagraficaSoggettiSchedaSanitariaDescrizione?: string,
    riposoSabato: boolean,
    riposoDomenicaFestivi: boolean,
    richiestaPianificazione: boolean,
    dataTurniAbilitatiPrecedente?: string,
    dataInizioTurnoFisso?: string,
    turnoFisso?: string,
    minutiElasticita?: number;
    recuperoRichiesto?: boolean;
    timbraturaSoloPresenza?: boolean;
    version: number
}