import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getExtendedEntityById, upd } from '../../services/services.service'
import { UnitaOperativaTurnoNumeroAddetti, elementIdProps, UnitaOperativaTurnoNumeroAddettiKeys } from '../../models/UnitaOperativeTurniNumeroAddetti';
import { UnitaOperativaEstesa } from '../../models/UnitaOperative';

const microservice = "unitaoperative";
const entity = "unitaoperativeturninumeroaddetti";

interface UnitaOperativeTurniNumeroAddettiState {
    statusValidUnitaOperativaTurniNumeroAddetti: StatusEnum,
    validUnitaOperativaTurniNumeroAddetti: UnitaOperativaTurnoNumeroAddetti[],
    unitaOperativaTurnoNumeroAddetti: UnitaOperativaTurnoNumeroAddetti | null,
    error: string | null
}

const initialState: UnitaOperativeTurniNumeroAddettiState = {
    statusValidUnitaOperativaTurniNumeroAddetti: StatusEnum.Succeeded,
    validUnitaOperativaTurniNumeroAddetti: [],
    unitaOperativaTurnoNumeroAddetti: null,
    error: null
}

export const fetchAllValidByFilter = createAsyncThunk(entity + '/fetchAllValidByFilter', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
    let response = await getExtendedEntityById(microservice, microservice, [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa]);
    let dat = response.data as UnitaOperativaEstesa;
    return dat.unitaOperativeTurniNumeroAddetti as UnitaOperativaTurnoNumeroAddetti[];
});

export const insert = createAsyncThunk(entity + '/insert', async (UnitaOperativaTurnoNumeroAddetti: UnitaOperativaTurnoNumeroAddetti) => {
    const response = await create(UnitaOperativaTurnoNumeroAddetti, microservice, entity);
    return response.data as UnitaOperativaTurnoNumeroAddetti;
});

export const update = createAsyncThunk(entity + '/update', async (UnitaOperativaTurnoNumeroAddetti: UnitaOperativaTurnoNumeroAddetti) => {
    const response = await upd(UnitaOperativaTurnoNumeroAddetti, microservice, entity);
    return response.data as UnitaOperativaTurnoNumeroAddetti;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const unitaOperativeTurniNumeroAddettiSlice = createSlice({
    name: entity,
    initialState,
    reducers: {

        reset: (state: UnitaOperativeTurniNumeroAddettiState) => {
            return initialState;
        },
        resetError: (state: UnitaOperativeTurniNumeroAddettiState) => {
            state.error = initialState.error;
            state.statusValidUnitaOperativaTurniNumeroAddetti = initialState.statusValidUnitaOperativaTurniNumeroAddetti;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllValidByFilter.pending, (state) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Loading;
        })
        builder.addCase(fetchAllValidByFilter.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Failed;
            state.validUnitaOperativaTurniNumeroAddetti = [];
        })
        builder.addCase(fetchAllValidByFilter.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaTurnoNumeroAddetti[]>) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Succeeded;
            state.validUnitaOperativaTurniNumeroAddetti = payload ?? [];
        })
        builder.addCase(insert.pending, (state) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Failed;
            state.unitaOperativaTurnoNumeroAddetti = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaTurnoNumeroAddetti>) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Succeeded;
            state.unitaOperativaTurnoNumeroAddetti = payload;
            state.validUnitaOperativaTurniNumeroAddetti = state.validUnitaOperativaTurniNumeroAddetti.concat([payload]);
        })
        builder.addCase(update.pending, (state) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Failed;
            state.unitaOperativaTurnoNumeroAddetti = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaTurnoNumeroAddetti>) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Succeeded;
            state.validUnitaOperativaTurniNumeroAddetti = state.validUnitaOperativaTurniNumeroAddetti.map(el => {
                if (elementIdProps.every(prop => el[prop] === payload[prop])) {
                    return { ...payload, version: payload.version + 1 };
                } else return el;
            });
            state.unitaOperativaTurnoNumeroAddetti = payload;
        })
        builder.addCase(physicalDel.pending, (state) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusValidUnitaOperativaTurniNumeroAddetti = StatusEnum.Succeeded;
            state.validUnitaOperativaTurniNumeroAddetti = state.validUnitaOperativaTurniNumeroAddetti.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaTurnoNumeroAddettiKeys] !== idObj.id));
        })
    }
});

export const { reset, resetError } = unitaOperativeTurniNumeroAddettiSlice.actions;
export default unitaOperativeTurniNumeroAddettiSlice.reducer;