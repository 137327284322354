import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { allFields, elementIdProps, elementRenderProps, TimbratureDipendentiKeys, } from "../../../models/TimbratureDipendente";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  reset as resetTimbrature,
  resetError as resetErrorTimbrature,
  insert,
  findbyidAnagraficaSoggettoTimbratureDipendenteCheck,
} from "../../../store/slices/timbratureSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { timbratureDipendentiPath } from "../../../utils/utilconst";
import DateFnsUtils from '@date-io/date-fns';
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { findbyidAnagraficaSoggettoTimbratureDipendente as fetchTimbratureBetweenDates, } from "../../../store/slices/timbratureSlice";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { Box, Grid, Paper, Modal, IconButton, makeStyles, createStyles, Theme, Button, Typography, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { createLookup, getDateYYYYMMDD_BackEnd, isUrlRoot, } from "../../../utils/utilfunctions";
import { fetchAllLookupByAbilitazione } from "../../../store/slices/anagraficaSoggettiSlice";
import { VersoTimbratura, VersoTimbraturaLookup } from "../../../utils/utildata";
import { TimbratureDipendenti } from "../../../models/TimbratureDipendente";
import { fetchByCodice } from '../../../store/slices/modelliTerminaleSlice'
import { fetchAllValidByIdModelloTerminale } from '../../../store/slices/modelliTerminaleCausaliSlice'
import { codiceTerminale } from '../../../utils/utilconst';
import SimpleIdSelects from "../selects/SimpleIdSelects";
import CloseIcon from '@material-ui/icons/Close';
import Clock from 'react-live-clock';
import clsx from 'clsx';
import format from "date-fns/format";
import { sub, add } from "date-fns";
import { openSnackBarError } from "../../../store/slices/snackBarSlice";
import GTModal from "../modal/GTModal";
import Maps from "../maps/Maps";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 200,
      maxWidth: 600,
      maxHeigth: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      borderRadius: 25,
      margin: 40,
      auto: 0,
      padding: 15,
    },
    date: {
      fontFamily: 'Orbitron',
      color: '#0f70b7',
      fontSize: 40,
    },
    clock: {
      // fontFamily: 'Courier New',
      color: '#0f70b7',
      fontSize: 60,
      fontWeight: 400
    },
    select: {
      flex: 1
    }
  }),
);

const TimbratureDipendenteW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const history = useHistory();
  const abilitazione = useAppSelector(state => getAbilitazione(state, timbratureDipendentiPath));
  const errorBE = useAppSelector(state => state.timbrature.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorTimbrature());
  }, [dispatch])

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const geolocationAPI = navigator.geolocation;

  const [lat, setLat] = useState<number>();
  const [long, setLong] = useState<number>();

  const [ip, setIp] = useState();

  const [map, setMap] = useState<any>(null);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const handleOpenMap = () => setOpenMap(true);

  const getIPAddress = useCallback(async () => {
    const response = await fetch('https://ipapi.co/json/');
    if (!response.ok) {
      dispatch(openSnackBarError(t('ipRetrievalFail')));
    }
    else {
      const data = await response.json();
      setIp(data.ip);
    }
  }, [dispatch, t]);

  useEffect(() => {
    getIPAddress();
  }, [getIPAddress]);

  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, timbratureDipendentiPath);

  const title = t('stampingParamDipendente');

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);
  const dateStartLabel = useMemo(() => t("startDateSelect"), [t]);
  const dateStartName = useMemo(() => t("startDateTitle"), [t]);
  const dateEndLabel = useMemo(() => t("endDateSelect"), [t]);
  const dateEndName = useMemo(() => t("endDateTitle"), [t]);

  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup);

  const modelloTerminale = useAppSelector(state => state.modelloTerminale.modelloTerminale);
  const causali = useAppSelector(state => state.causali.validCausali);

  const causaliLookup = useMemo(() => {
    return createLookup(causali, 'codice', ['descrizione']);
  }, [causali]);

  const [states, setStates] = useState<{
    [selectName: string]: string | number | null;
  }>({
    [dateStartName]: getDateYYYYMMDD_BackEnd(new Date(new Date().getFullYear(), new Date().getMonth(), 1)),
    [dateEndName]: getDateYYYYMMDD_BackEnd(new Date()).replace("T00:00:00", "T23:59:59"),
  });

  const fixedProps = useMemo(() => {
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
      inizioData: states[dateStartName],
      fineData: (states[dateEndName] as string)?.replace("T00:00:00", "T23:59:59"),
    }
  }, [dateEndName, dateStartName, soggettoSelectName, states]);

  const [causale, setCausale] = useState<string>('');

  const handleChange = useCallback((event) => {
    setCausale(event.target.value);
  }, []);

  useEffect(() => {
    dispatch(fetchAllLookupByAbilitazione());
    dispatch(fetchByCodice([{
      id: codiceTerminale,
      name: 'modelloTerminale'
    }]))
  }, [dispatch]);

  useEffect(() => {
    if (modelloTerminale) {
      dispatch(fetchAllValidByIdModelloTerminale({
        idModelloTerminale: modelloTerminale.idModelloTerminale
      }));
    }
  }, [dispatch, modelloTerminale]);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => { setIsOpen(true); }
  const handleClose = () => {
    setIsOpen(false);
    setCausale('');
  }

  const insertCallback = () => {
    setIsLoading(true)
    dispatch(findbyidAnagraficaSoggettoTimbratureDipendenteCheck({
      idAnagraficaSoggetto: Number(fixedProps.idAnagraficaSoggetto),
      inizioData: format(sub(new Date(), {
        hours: 12
      }), "yyyy-LL-dd'T'HH:mm:ss"),
      fineData: format(add(new Date(), {
        hours: 1
      }), "yyyy-LL-dd'T'HH:mm:ss")
    }))
      .then(() => {
        setIsLoading(false)
        handleOpen();
      })
  }

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<TimbratureDipendenti>>>([]);
  useEffect(() => {
    return setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<TimbratureDipendenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as TimbratureDipendentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.field === 'tracciato') {
          obj.render = (rowData: TimbratureDipendenti) => {
            return <Button
              onClick={() => {
                handleOpenMap();
                const token = rowData.tracciato.split('+')?.[1];
                let lat = token?.split('N')?.[0].slice(1);
                let lng = token?.split('N')?.[1];

                lat = lat?.slice(0, lat?.lastIndexOf('.'));
                lng = lng?.slice(0, lng?.lastIndexOf('.'));

                setMap({ lat: Number(lat), lng: Number(lng) });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          }
        }

        if (f.lookupField) {
          switch (f.field) {
            case "versoTimbratura":
              obj.lookup = VersoTimbraturaLookup
              break;
            case "causale":
              obj.lookup = causaliLookup
              break;
          }
        }
        return obj;
      })
    );
  }, [allFieldsState, causaliLookup, dispatch, setColumns, t]);

  const statusValidTimbrature = useAppSelector(state => state.timbrature.statusValidTimbrature);
  const validTimbrature = useAppSelector(state => state.timbrature.validTimbrature);

  const [data, setData] = useState<Array<TimbratureDipendenti>>([]);
  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate = '';
    if (d) {
      let month = d.getMonth() < 9 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    field && setStates(prev => { return { ...prev, [field]: fieldDate } });
  };

  /**
   * Get user coordinates
   */
  useEffect(() => {
    if (!geolocationAPI) {
    } else {
      geolocationAPI.getCurrentPosition((position) => {
        const { coords } = position;
        setLat(coords.latitude);
        setLong(coords.longitude);
      }, error => { });
    }
  }, [geolocationAPI]);

  useEffect(() => {
    setData(validTimbrature as TimbratureDipendenti[]);
  }, [validTimbrature]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
      dispatch(resetTimbrature());

    };
  }, [dispatch]);

  const Map = useMemo(() => {
    return map && map.lat && map.lng
      ? <Maps map={map} />
      : null
  }, [map]);

  const _dateStartNameValue = useMemo(() => states[dateStartName], [dateStartName, states]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: soggettoSelectName,
                    lookup: lookupAnagraficaSoggettoPerAbilitazione,
                    label: soggettoSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 10 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  variant="inline"
                  label={dateStartLabel}
                  format={"dd/MM/yyyy"}
                  onChange={(e) => handleDateChange(e, dateStartName)}
                  autoOk={true}
                  disabled={isCurrentPageNotIndex}
                  value={states[dateStartName] ?? null}
                  views={["year", "month", "date"]}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            {
              _dateStartNameValue &&
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <DatePicker
                    fullWidth
                    variant="inline"
                    label={dateEndLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e, dateEndName)}
                    autoOk={true}
                    minDate={new Date(_dateStartNameValue)}
                    maxDate={new Date()}
                    disabled={isCurrentPageNotIndex}
                    value={states[dateEndName] ?? null}
                    views={["year", "month", "date"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>

      <Box marginTop={2}>
        {states[soggettoSelectName]
          ? (
            <CrudMaterialTableWithoutLogicDelete
              abilitazione={abilitazione}
              title={title}
              columns={columns}
              columnsButton={true}
              data={data}
              insertCallback={insertCallback}
              elementIdProps={elementIdProps}
              elementRenderProps={elementRenderProps}
              fetchAllValid={fetchTimbratureBetweenDates}
              update={undefined}
              statusValid={statusValidTimbrature}
              errorBE={errorBE}
              logoUri={logoUri}
              localizedDatePicker={true}
              isExportLandscape={false}
              fixedProps={fixedProps}
              isLoading={isLoading}
              resetErrorCallback={resetError}
              extraOptions={{
                maxBodyHeight: 460,
                draggable: false,
                rowStyle: (rowData: TimbratureDipendenti) => ({
                  backgroundColor: (rowData.eliminato) ? '#FF0000' : '#FFF'
                })
              }}
            />

          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {soggettoSelectLabel}
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>

      <Modal
        className={clsx(classes.container)}
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={clsx(classes.paper)} >
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>

            </Grid  >
            <Grid item xs >
              <Box display='flex' alignItems='center' flexDirection={'column'}>
                <Clock format="DD/MM/YYYY" ticking={true} className={clsx(classes.date)} />
                <Clock format="HH:mm:ss" ticking={true} className={clsx(classes.clock)} />
              </Box>
              <Box display='flex' px={5} py={2} alignItems='center' justifyContent='space-evenly' flexDirection={'row'} borderColor='#cecece' borderTop={1}>
                <FormControl className={classes.select}>
                  <InputLabel id="causale-select-label">Causale</InputLabel>
                  <Select
                    labelId="causale-select-label"
                    id="causale-select"
                    value={causale}
                    onChange={handleChange}
                  >
                    <MenuItem value={''}>Nessuna</MenuItem>
                    {causali.map((_causale, index) => {
                      return (
                        <MenuItem key={'causale-' + index} value={_causale.codice}>
                          {_causale.descrizione}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Box>
              <Box display='flex' justifyContent={'space-between'} paddingTop={4}  >
                <Button
                  color="primary"
                  size="large"
                  variant="contained"
                  onClick={() => {
                    const dataCorrente = format(new Date(), "yyyy-LL-dd'T'HH:mm:ss");
                    if (ip) {
                      dispatch(insert({
                        idAnagraficaSoggetto: Number(fixedProps.idAnagraficaSoggetto),
                        tracciato: "E" + ("0000000000" + Number(fixedProps.idAnagraficaSoggetto)).substring(("0000000000" + Number(fixedProps.idAnagraficaSoggetto)).length - 10)
                          + dataCorrente + "+L" + (lat + '.000' ? lat + '.000' : "0000000") + "N" + (long + '.000' ? long + '.000' : "0000000"),
                        forzaturaAnagraficaSoggetto: false,
                        modelloTerminale: codiceTerminale,
                        oraInizioTurno: dataCorrente.substring(11, 16),
                        oraFineTurno: undefined,
                        identificativoTerminale: "WW",
                        statoFunzionamentoTerminale: "0",
                        indirizzoIPV4: ip,
                        versoTimbratura: VersoTimbratura.ENTRATA,
                        data: dataCorrente,
                        idOrganizzazione: 0,
                        idTimbratura: undefined,
                        nome: undefined,
                        cognome: undefined,
                        version: 0,
                        presenza: undefined,
                        eliminato: false,
                        causale: causale === '' ? undefined : causale
                      }));
                    }
                    handleClose()
                  }
                  }
                >
                  {t("entrata")}
                </Button>

                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    const dataCorrente = format(new Date(), "yyyy-LL-dd'T'HH:mm:ss");
                    if (ip) {
                      dispatch(insert({
                        idAnagraficaSoggetto: Number(fixedProps.idAnagraficaSoggetto),
                        tracciato: "U" + ("0000000000" + Number(fixedProps.idAnagraficaSoggetto)).substring(("0000000000" + Number(fixedProps.idAnagraficaSoggetto)).length - 10)
                          + dataCorrente + "+L" + (lat + '.000' ? lat + '.000' : "0000000") + "N" + (long + '.000' ? long + '.000' : "0000000"),
                        forzaturaAnagraficaSoggetto: false,
                        modelloTerminale: codiceTerminale,
                        oraInizioTurno: undefined,
                        oraFineTurno: dataCorrente.substring(11, 16),
                        identificativoTerminale: "WW",
                        statoFunzionamentoTerminale: "0",
                        indirizzoIPV4: ip,
                        versoTimbratura: VersoTimbratura.USCITA,
                        data: dataCorrente,
                        idOrganizzazione: 0,
                        idTimbratura: undefined,
                        nome: undefined,
                        cognome: undefined,
                        version: 0,
                        presenza: undefined,
                        eliminato: false,
                        causale: causale === '' ? undefined : causale
                      }));
                    }
                    handleClose()
                  }}
                >
                  {t("uscita")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Modal>

      {/* Google Map */}
      {
        openMap &&
        <GTModal
          openState={{
            open: openMap,
            setOpen: setOpenMap
          }}
        >
          {
            Map ??
            <Typography>
              Impossibile rilevare la posizione
            </Typography>
          }
        </GTModal>
      }
    </>
  )
}

export default TimbratureDipendenteW;
