
import { Fields } from "./Fields";

export type StruttureSediKeys = keyof StruttureSedi;

export const elementIdProps: StruttureSediKeys[] = ['idStruttura', 'idStrutturaSede'];
export const elementRenderProps: StruttureSediKeys[] = ['descrizione'];

export const allFields: Fields[] = [
  {
    field: "descrizione", type: 'string', required: true, show: true, titleKey: "descriptionParam"
  },
  {
    field: "indirizzo", type: 'string', required: false, show: true, titleKey: "addressParam"
  },
  {
    field: "latitudine", type: 'string', required: true, show: true, titleKey: "latitudeParam"
  },
  {
    field: "longitudine", type: 'string', required: true, show: true, titleKey: "longitudeParam"
  },
  {
    field: "mappa", required: false, show: true, titleKey: "positionParam", editable: "never"
  }
];

export interface StruttureSedi {
  idStruttura: number,
  idStrutturaSede: number,

  descrizione: string,
  indirizzo?: string,
  latitudine: string,
  longitudine: string,

  version: number,
};
